import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  QueryDocumentSnapshot,
  serverTimestamp,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import create from 'zustand';

import { db, updateActivity } from 'utils/api/firebase';

import { TODO } from './global.types';
import { Thread } from './threads.types';

import { toast } from '@/components/ui/use-toast';

export interface ThreadsStoreType {
  initialLoading: boolean;
  setInitialLoading: (initialLoading: boolean) => void;

  threads: Thread[];
  syncThread: (thread: Thread) => void;

  lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>;
  setLastVisible: (
    lastVisible: QueryDocumentSnapshot<DocumentData, DocumentData>
  ) => void;

  hasMore: boolean;
  setHasMore: (hasMore: boolean) => void;

  limitCount: number;

  getThreads: (wsId: string, uid: string, all?: boolean) => void;

  getThread: (wsId: string, threadId: string) => TODO;

  createThread: (wsId: string, uid: string, data: TODO, tId?: string) => void;

  updateThread: (docRef: TODO, data: TODO, uid?: string) => void;

  createThreadMessage: (
    wsId: string,
    tId: string,
    uid: string,
    data: TODO
  ) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useThreadsStore = create<ThreadsStoreType>((set, get) => ({
  initialLoading: true,
  setInitialLoading: initialLoading => {
    set(() => ({ initialLoading }));
  },

  threads: [],
  syncThread: thread => {
    set(({ threads }) => {
      const threadIndex = threads.findIndex(t => t.id === thread.id);
      if (threadIndex === -1) return { threads: [...threads, thread] };
      const updatedThreads = threads.map((t, index) =>
        index === threadIndex ? thread : t
      );
      return {
        threads: updatedThreads.sort((a, b) => b.createdAt - a.createdAt),
      };
    });
  },
  hasMore: true,
  setHasMore: hasMore => {
    set(() => ({ hasMore }));
  },
  limitCount: 13,

  lastVisible: null,
  setLastVisible: lastVisible => {
    set(() => ({ lastVisible }));
  },

  getThreads: async (wsId, uid, all = false) => {
    if (!wsId || !uid) return;
    const {
      limitCount,
      lastVisible,
      syncThread,
      hasMore,
      setHasMore,
      setLastVisible,
      getThreads,
      setInitialLoading,
    } = get();

    if (!hasMore) return;

    // init q
    let q = query(
      collection(db, 'users', wsId, 'threads'),
      or(
        where('createdBy', '==', uid),
        where('createdBy', '==', null),
        where('isShared', '==', true),
        where('uid', '==', uid)
      )
    );
    // add order desc and limit
    q = query(q, orderBy('createdAt', 'desc'), limit(limitCount));
    // has no more starred
    if (lastVisible) {
      q = query(q, startAfter(lastVisible));
    }
    const documentSnapshots = await getDocs(q);
    setInitialLoading(false);
    documentSnapshots.forEach(doc => {
      syncThread({ id: doc.id, ref: doc.ref, ...doc.data() });
    });
    if (documentSnapshots.empty || documentSnapshots.size < limitCount)
      setHasMore(false);

    if (documentSnapshots.size) {
      setLastVisible(documentSnapshots.docs[documentSnapshots.size - 1]);
      if (all) getThreads(wsId, uid, all);
    }
  },

  getThread: async (wsId, threadId) => {
    if (!wsId || !threadId) return;
    const { threads, syncThread } = get();
    const syncedThread = threads.find(t => t.id === threadId);
    if (syncedThread) return syncedThread;
    const docRef = doc(db, 'users', wsId, 'threads', threadId);
    const docSnap = await getDoc(docRef);
    const thread = docSnap.exists()
      ? { id: docSnap.id, ref: docSnap.ref, ...docSnap.data() }
      : null;
    if (thread) syncThread(thread);
    return thread;
  },

  createThread: async (wsId, uid, data, tId) => {
    try {
      if (!wsId) throw new Error('Workspace ID is required.');
      if (tId) {
        await setDoc(doc(db, 'users', wsId, 'threads', tId), {
          ...data,
          createdBy: uid,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        });
        updateActivity({ wsId, uid, field: 'threads' });
        return;
      }
      await addDoc(collection(db, 'users', wsId, 'threads'), {
        ...data,
        createdBy: uid,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.log('useThreadsStore -- createThread -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },

  createThreadMessage: async (wsId, tId, uid, data) => {
    if (!wsId || !tId || !uid || !data) return;
    await addDoc(collection(db, 'users', wsId, 'threads', tId, 'messages'), {
      ...data,
      role: 'user',
      uid,
      createdAt: serverTimestamp(),
    });
  },

  updateThread: async (docRef, data, uid) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { starred, ...restProps } = data;
    try {
      await updateDoc(docRef, {
        ...restProps,
        ...(data['starred'] !== undefined && uid
          ? {
              starred:
                data['starred'] === 'remove'
                  ? arrayRemove(uid)
                  : arrayUnion(uid),
            }
          : {}),
        updatedAt: serverTimestamp(),
      });
    } catch (error) {
      console.log('useThreadsStore -- updateThread -- error:', error);
      toast({
        title: 'Something went wrong. Please try again.',
      });
    }
  },
}));

export default useThreadsStore;

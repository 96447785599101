import {
  addDoc,
  collection,
  doc,
  onSnapshot,
  or,
  query,
  where,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import {
  useCollection,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore';

import { TODO } from 'store/global.types';
import { db, functions } from 'utils/api/firebase';
import { stripeClient } from 'utils/api/stripe';

import { useToast } from '@/components/ui/use-toast';

type Props = {
  wsId: string;
};

const useSubscriptions = ({ wsId = 'default' }: Props) => {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);

  const [productsData] = useCollection(
    query(collection(db, 'products'), where('active', '==', true))
  );

  const [subscriptionsData, subscriptionsDataLoading] = useCollection(
    query(
      collection(db, 'customers', wsId, 'subscriptions'),
      or(where('status', '==', 'trialing'), where('status', '==', 'active'))
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [cancelsData] = useCollection(
    query(
      collection(db, 'customers', wsId, 'subscriptions'),
      where('status', '!=', 'active')
    ),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [stripeData] = useDocumentDataOnce(doc(db, 'others', 'stripe.com'));

  const [wsClaimsData] = useDocumentDataOnce(doc(db, 'claims', wsId));

  const onManageSubscription = useCallback(async () => {
    setLoading(true);
    try {
      const functionRef = httpsCallable(
        functions,
        'ext-firestore-stripe-payments-createPortalLink'
      );
      const { data }: { data: TODO } = await functionRef({
        returnUrl: window.location.origin,
      });
      if (!data?.url) return;
      window.location.assign(data.url);
    } catch (error) {
      if (process.env.IS_DEBUG)
        console.log(
          'useSubscriptions -- onManageSubscription -- error:',
          error
        );
    }
  }, []);

  const stripeRoles = useMemo(() => {
    if (!subscriptionsData) return ['free'];
    const tiers = ['basic', 'standard', 'pro', 'studio'];
    const roles = subscriptionsData.docs.map(sub => sub.data().role);
    return ['free', ...tiers.filter(role => roles.includes(role))];
  }, [subscriptionsData]);

  const wasCustomer = useMemo(() => {
    if (!cancelsData) return false;
    return !!cancelsData.docs.length;
  }, [cancelsData]);

  const onSubscription = useCallback(
    async (priceId: string, role = '') => {
      setLoading(true);
      try {
        const checkoutSessionsCollectionRef = collection(
          db,
          'customers',
          wsId,
          'checkout_sessions'
        );
        const docRef = await addDoc(checkoutSessionsCollectionRef, {
          price: priceId,
          mode: 'subscription',
          automatic_tax: true,
          collect_shipping_address: false,
          allow_promotion_codes: true,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
          ...(!wasCustomer &&
            stripeData?.trial_period_days && {
              trial_period_days: stripeData?.trial_period_days,
            }),
          ...(!wasCustomer &&
            role &&
            stripeData?.[role] && {
              trial_period_days: stripeData?.[role]?.trial_period_days,
            }),
        });
        onSnapshot(docRef, async snap => {
          const data = snap.data();
          if (!data?.sessionId) return;
          const stripe = await stripeClient();
          stripe.redirectToCheckout({ sessionId: data?.sessionId });
        });
      } catch (error) {
        if (process.env.IS_DEBUG)
          console.log('useSubscriptions -- onSubscription -- error:', error);
        toast({
          title: 'Something went wrong. Please try again.',
        });
      }
    },
    [stripeData, toast, wasCustomer, wsId]
  );

  return {
    loading,
    subscriptionsDataLoading,
    stripeRole: wsClaimsData?.isInternal
      ? wsClaimsData?.stripeRole || 'basic'
      : _.last(stripeRoles) || 'free',
    wasCustomer,
    productsData,
    onManageSubscription,
    onSubscription,
  };
};

export default useSubscriptions;

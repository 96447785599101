import { AnimatePresence } from 'framer-motion';
import { useSession } from 'next-auth/react';
import React from 'react';

import PageView from 'components/pageViews/PageView/PageView';
import useCommon from 'hooks/useCommon';
import useUser from 'hooks/useUser';
import useWorkspace from 'hooks/useWorkspace';
import { useGlobalStore } from 'store';
import { isAccountPage, isDashboardPage } from 'utils/routes';

import InviteDialog from './InviteDialog';
import LayoutCommandDialog from './LayoutCommandDialog';
import LayoutDescribeDialog from './LayoutDescribeDialog';
import LayoutGenerateDialog from './LayoutGenerateDialog';
import Intercom from './LayoutManager.intercom';
import LayoutSharedForms from './LayoutManager.sharedForms';
import LayoutNotifications from './LayoutNotifications';
import LayoutTrialDialog from './LayoutTrialDialog';

import { Toaster as ToasterSonnar } from '@/components/ui/sonner';
import { Toaster } from '@/components/ui/toaster';

export interface LayoutManagerProps {
  children: React.ReactNode | React.ReactNode[];
}

const defaultProps: Partial<LayoutManagerProps> = {};

const SyncCollections = ({ wsId }) => {
  const {} = useWorkspace({ wsId });
  return <></>;
};

const SyncUser = ({ uid }) => {
  const {} = useUser({ uid });
  return <></>;
};

const LayoutManager: React.FC<LayoutManagerProps> = ({ children }) => {
  const { inviteId } = useGlobalStore();
  const { router, wsId, wsIdLocal } = useCommon();
  const { data: session } = useSession();
  const uid = session?.user?.id;

  return (
    <>
      {(wsIdLocal || wsId) && <SyncCollections wsId={wsIdLocal || wsId} />}
      {uid && <SyncUser {...{ uid }} />}
      <AnimatePresence mode="wait" initial={true}>
        <PageView {...{ uid }}>{children}</PageView>
      </AnimatePresence>
      {(isDashboardPage(router) || isAccountPage(router)) && (
        <>
          <LayoutNotifications />
          <LayoutCommandDialog />
          <LayoutGenerateDialog />
          <LayoutDescribeDialog />
          <LayoutTrialDialog />
        </>
      )}
      {isDashboardPage(router) && <LayoutSharedForms />}
      {inviteId && uid && <InviteDialog />}
      <Toaster />
      <ToasterSonnar position="bottom-center" offset={12} />
      <Intercom />
    </>
  );
};

LayoutManager.defaultProps = defaultProps;

export default LayoutManager;

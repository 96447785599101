import { MoonIcon, SunIcon } from '@radix-ui/react-icons';
import { Bell, Bolt, Info, LogOut, Plus, SwatchBook } from 'lucide-react';
import { useTheme } from 'next-themes';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { WsAvatar } from 'components/WsAvatar';
import useCommon from 'hooks/useCommon';
import { useGlobalStore } from 'store';
import useUsersStore from 'store/users';
import { ROUTES } from 'utils/routes';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

export interface SidebarAccountProps {
  uid?: string;
}

const defaultProps: Partial<SidebarAccountProps> = {};

const SidebarAccount: React.FC<SidebarAccountProps> = ({ uid }) => {
  const { setNotificationsOpen } = useGlobalStore();
  const { setCommandOpen } = useGlobalStore();

  const { users, getUser } = useUsersStore();
  const { wsId, router, routeTo, onLogout, setWsIdLocal } = useCommon();
  const { theme, setTheme } = useTheme();

  const onAccountClick = useCallback(() => {
    routeTo(ROUTES.ACCOUNT_ORG, uid);
  }, [routeTo, uid]);

  useEffect(() => {
    getUser(uid);
    getUser(wsId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const user = useMemo(() => {
    return users.find(user => user.id === uid);
  }, [uid, users]);

  const workspace = useMemo(() => {
    return users.find(user => user.id === wsId);
  }, [wsId, users]);

  return (
    <div className={'sticky bottom-0 p-3 flex flex-col items-center gap-4'}>
      <div
        className={
          'bg-muted rounded-full aspect-square w-10 flex items-center justify-center cursor-pointer'
        }
        onClick={() => {
          setCommandOpen(true);
        }}
      >
        <Plus className={'h-4 w-4'} />
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className={'relative aspect-square'}>
            <WsAvatar
              {...{
                photoURL: user?.photoURL,
                name: user?.name,
                size: 'large',
              }}
            />
            <div className={'absolute bottom-0 left-0'}>
              <WsAvatar
                {...{
                  photoURL: workspace?.organizationImageURL || '',
                  name: workspace?.organizationName || '',
                  size: 'xs',
                  hasOutline: true,
                  isWorkspace: true,
                }}
              />
            </div>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent side={'right'} align={'end'}>
          {!!user?.workspaceIds?.length && (
            <DropdownMenuLabel>Workspaces</DropdownMenuLabel>
          )}
          {[...(user?.workspaceIds || []), uid]?.map(id => (
            <DropdownMenuItem
              key={id}
              onClick={async () => {
                if (wsId === id) return;
                setWsIdLocal(id);
                await router.replace(ROUTES.WORKSPACE);
                router.reload();
              }}
            >
              <Ws wsId={id} />
            </DropdownMenuItem>
          ))}
          <DropdownMenuSeparator />
          <DropdownMenuItem
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              window?.Intercom?.('showSpace', 'help');
            }}
          >
            <Info className={'h-4 w-4 mr-2'} />
            <span className={'text-sm font-normal text-muted-foreground'}>
              Help
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setNotificationsOpen(true)}>
            <Bell className={'h-4 w-4 mr-2'} />
            <span className={'text-sm font-normal text-muted-foreground'}>
              Notifications
            </span>
          </DropdownMenuItem>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              {theme === 'light' && <SunIcon className="h-4 w-4 mr-2" />}
              {theme === 'dark' && <MoonIcon className="h-4 w-4 mr-2" />}
              <span className={'text-sm font-normal text-muted-foreground'}>
                Toggle theme
              </span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem onClick={() => setTheme('light')}>
                  Light
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setTheme('dark')}>
                  Dark
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setTheme('system')}>
                  System
                </DropdownMenuItem>
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>
          <DropdownMenuItem onClick={() => onAccountClick()}>
            <Bolt className={'h-4 w-4 mr-2'} />
            <span className={'text-sm font-normal text-muted-foreground'}>
              Account
            </span>
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => onLogout()}>
            <LogOut className={'h-4 w-4 mr-2'} />
            <span className={'text-sm font-normal text-muted-foreground'}>
              Logout
            </span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

SidebarAccount.defaultProps = defaultProps;

export default SidebarAccount;

const Ws = (props: { wsId: string }) => {
  const { wsId } = props;
  const { getUser } = useUsersStore();
  const [user, setUser] = useState(null);
  useEffect(() => {
    getUser(wsId).then(u => setUser(u));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const name = user?.organizationName || user?.name || '';
  const imageUrl = user?.organizationImageURL || '';
  return (
    <>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={name}
          className={'rounded-full object-cover w-4 h-4 overflow-hidden mr-2'}
        />
      ) : (
        <SwatchBook className={'h-4 w-4 mr-2'} />
      )}
      <span
        className={
          'text-sm text-muted-foreground line-clamp-1 break-all whitespace-normal'
        }
      >{`${name || 'Workspace'}`}</span>
    </>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import { PanelLeftOpen, PanelRightOpen } from 'lucide-react';
import * as Icons from 'lucide-react';
import React, { ElementType, useCallback, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import useCommon from 'hooks/useCommon';
import { TODO } from 'store/global.types';
import { superAdminsExtended } from 'utils/config';
import {
  isAccountPage,
  isDashboardPage,
  isThreadPage,
  replaceDynamicSegments,
  ROUTES,
} from 'utils/routes';

import { Separator } from '@/components/ui/separator';
import { cn } from '@/lib/utils';

export interface SidebarNavProps {}

const defaultProps: Partial<SidebarNavProps> = {};

export const DashboardItems = {
  steps: [
    {
      id: 'dashboard',
      icon: 'LayoutDashboard',
      label: 'Dashboard',
    },
    {
      id: 'imaginations',
      icon: 'Sparkles',
      label: 'Imaginations',
    },
    {
      id: 'threads',
      icon: 'MessageSquareQuote',
      label: 'Threads',
    },
    {
      id: 'characters',
      icon: 'VenetianMask',
      label: 'Characters',
      parentId: 'scripts',
    },
    {
      id: 'voiceovers',
      icon: 'AudioWaveform',
      label: 'Voiceovers',
      parentId: 'scripts',
    },
    {
      id: 'brands',
      icon: 'Pyramid',
      label: 'Brands',
    },
    {
      id: 'projects',
      icon: 'LibraryBig',
      label: 'Projects',
    },
    {
      id: 'collections',
      icon: 'Bookmark',
      label: 'Collections',
    },
    {
      id: 'divider1',
      label: 'Divider',
      superAdminExtended: true,
    },
    {
      id: 'playground',
      icon: 'PencilRuler',
      label: 'Playground',
      superAdminExtended: true,
    },
  ],
};

export const AccountItems = {
  steps: [
    {
      id: 'account_org',
      icon: 'Building',
      label: 'Organization',
    },
    {
      id: 'account_profile',
      icon: 'CircleUser',
      label: 'Profile',
    },
    {
      id: 'account_team',
      icon: 'Users',
      label: 'Team',
    },
    // {
    //   id: 'account_integrations',
    //   icon: 'Blocks',
    //   label: 'Integrations',
    // },
    {
      id: 'account_billing',
      icon: 'Receipt',
      label: 'Subscription',
    },
    {
      id: 'account_usage',
      icon: 'BarChart2',
      label: 'Usage',
    },
  ],
};

const SidebarNav: React.FC<SidebarNavProps> = () => {
  const { router, wsId } = useCommon();
  const isCollapsible = isThreadPage(router);

  const onChange = useCallback(
    (id: string) => {
      if (id === 'threads' && isThreadPage(router)) return;
      router.push(
        replaceDynamicSegments(
          ROUTES[_.snakeCase(id).toUpperCase()],
          router.query
        )
      );
    },
    [router]
  );

  const activeStepId = useMemo(() => {
    for (const [key, value] of Object.entries(ROUTES)) {
      if (value === router.route) return _.camelCase(key);
    }
    return '';
  }, [router.route]);

  const activeParentId = useMemo(() => {
    if (activeStepId === 'characters') return 'scripts';
    if (activeStepId === 'voiceOvers') return 'scripts';
  }, [activeStepId]);

  const items = useMemo(() => {
    let steps = [];
    if (isDashboardPage(router)) steps = DashboardItems.steps;
    if (isAccountPage(router)) steps = AccountItems.steps;

    return steps
      .filter(step => {
        if (step.superAdminExtended) {
          return superAdminsExtended.includes(wsId);
        }
        return true;
      })
      .map(step => {
        const item: TODO = {
          ...step,
          isActive: step.id === activeStepId || step.id === activeParentId,
          ...(step.subItems && {
            subItems: step.subItems.map(subItem => {
              const subItemProps: TODO = {
                ...subItem,
                isActive: subItem.id === activeStepId,
              };
              return subItemProps;
            }),
          }),
        };
        return item;
      });
  }, [activeParentId, activeStepId, router, wsId]);

  const [isThreadPageCollapsed, setIsThreadPageCollapsed] = useLocalStorage(
    'isThreadPageCollapsed',
    false
  );

  return (
    <nav className={cn('flex flex-col items-center')}>
      <ul className={'space-y-3'}>
        {items.map(item => {
          const { id, icon, label, isActive } = item;
          if (label === 'Divider') {
            return (
              <li key={id}>
                <Separator className={'my-4'} />
              </li>
            );
          }
          const IconComponent = Icons[
            icon as keyof typeof Icons
          ] as ElementType;

          return (
            <li
              key={id}
              className={
                'flex lg:flex-col items-center gap-1 cursor-pointer group'
              }
              onClick={() => onChange(id)}
            >
              <div
                className={cn(
                  'w-9 h-9  rounded-md flex items-center justify-center group-hover:bg-muted',
                  isActive ? 'bg-muted' : ''
                )}
              >
                {IconComponent && (
                  <IconComponent
                    className={cn(
                      'h-6 w-6',
                      isActive ? 'text-foreground' : 'text-muted-foreground'
                    )}
                    {...{
                      ...(isActive ? { fill: 'currentColor' } : {}),
                      ...(isActive
                        ? { stroke: 'hsl(var(--muted))', strokeWidth: 0.5 }
                        : { strokeWidth: 1.5 }),
                    }}
                  />
                )}
              </div>
              <span
                className={cn(
                  'text-xs line-clamp-1',
                  isActive ? 'text-foreground' : 'text-muted-foreground'
                )}
              >
                {label}
              </span>
            </li>
          );
        })}
        {isCollapsible && (
          <>
            <li>
              <Separator className={'my-4 hidden lg:block'} />
            </li>

            <li
              className={
                'hidden lg:flex flex-col items-center gap-1 cursor-pointer group '
              }
              onClick={() => {
                if (isThreadPage(router)) {
                  setIsThreadPageCollapsed(!isThreadPageCollapsed);
                }
              }}
            >
              <div
                className={cn(
                  'w-9 h-9  rounded-md flex items-center justify-center text-muted-foreground group-hover:bg-muted group-hover:text-foreground'
                )}
              >
                {isThreadPageCollapsed ? (
                  <PanelLeftOpen className={'h-6 w-6'} strokeWidth={1.5} />
                ) : (
                  <PanelRightOpen className={'h-6 w-6'} strokeWidth={1.5} />
                )}
              </div>

              <span
                className={
                  'text-xs line-clamp-1 text-muted-foreground group-hover:text-foreground'
                }
              >
                {isThreadPageCollapsed ? 'Expand' : 'Collapse'}
              </span>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

SidebarNav.defaultProps = defaultProps;

export default SidebarNav;

import { AnimatePresence, motion } from 'framer-motion';
import { useSession } from 'next-auth/react';
import { useTheme } from 'next-themes';
import React from 'react';

import SidebarAccount from 'components/Sidebar/Sidebar.account';
import SidebarDashboard from 'components/Sidebar/Sidebar.dashboard';
import SidebarNav from 'components/Sidebar/Sidebar.nav';
import useCommon from 'hooks/useCommon';
import { isAccountPage, isDashboardPage } from 'utils/routes';
import { pageMotionProps } from 'utils/styles/animations';

import { ScrollArea } from '@/components/ui/scroll-area';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { cn } from '@/lib/utils';
import useSvgIcon from 'growl/hooks/useSvgIcon';

export interface PageViewProps {
  uid?: string;
  children: React.ReactNode | React.ReactNode[];
}

const defaultProps: Partial<PageViewProps> = {};

const PageView: React.FC<PageViewProps> = ({ uid, children }) => {
  const { router } = useCommon();
  return (
    <div
      className={cn(
        'grid items-start h-full transition-all lg:grid-cols-[0px_1fr]',
        (isDashboardPage(router) || isAccountPage(router)) &&
          'lg:grid-cols-[90px_1fr]'
      )}
    >
      <div
        className={cn(
          'hidden lg:grid sticky top-0 overflow-hidden h-screen grid-rows-[auto_1fr_auto]'
        )}
      >
        <SidebarDashboard />
        <ScrollArea>
          <SidebarNav />
        </ScrollArea>
        {uid && <SidebarAccount {...{ uid }} />}
      </div>

      <AnimatePresence mode="wait">
        <motion.div {...pageMotionProps} className={'bg-muted/55 min-h-screen'}>
          {children}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

PageView.defaultProps = defaultProps;

export default PageView;

export const Header = (props: { children: React.ReactNode }) => {
  const { data } = useSession();
  const { children } = props;
  const { theme } = useTheme();
  const Logo = useSvgIcon(
    `logo-square-solid${theme === 'dark' ? '-invert' : ''}`
  );

  return (
    <div
      className={
        'sticky top-0 z-10 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 h-14 px-4 lg:px-8'
      }
    >
      <div
        className={
          'w-full h-full grid gap-4 grid-cols-[auto_1fr] lg:grid-cols-[1fr] items-center'
        }
      >
        <Sheet>
          <SheetTrigger asChild>
            <Logo.SvgIcon
              className={'block lg:hidden h-8 w-8 cursor-pointer'}
            />
          </SheetTrigger>
          <SheetContent side={'left'} className={'p-0 w-auto px-8'}>
            <div
              className={cn(
                'grid sticky top-0 overflow-hidden h-screen grid-rows-[auto_1fr_auto]'
              )}
            >
              <SidebarDashboard />
              <ScrollArea>
                <SidebarNav />
              </ScrollArea>
              {data?.user?.id && <SidebarAccount {...{ uid: data.user.id }} />}
            </div>
          </SheetContent>
        </Sheet>
        {children}
      </div>
    </div>
  );
};

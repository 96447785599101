import { SwatchBook, User } from 'lucide-react';
import React, { useEffect } from 'react';

import useUsersStore from 'store/users';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

export const WsAvatars = (props: { uids: string[] }) => {
  const { uids } = props;
  const { users, getUser } = useUsersStore();
  useEffect(() => {
    uids.forEach(email => {
      getUser(email);
    });
  }, [getUser, uids]);
  return (
    <div className={'flex -space-x-2 mt-1'}>
      {uids.map(uid => {
        const user = users.find(u => u.id === uid || u.email === uid);
        const photoURL = user?.photoURL;
        const name = user?.name || user?.displayName || uid;
        return (
          <Tooltip key={uid} delayDuration={100}>
            <TooltipTrigger className={'hover:z-10'}>
              <WsAvatar
                {...{
                  size: 'small',
                  photoURL,
                  name,
                }}
              />
            </TooltipTrigger>
            {name && <TooltipContent>{name}</TooltipContent>}
          </Tooltip>
        );
      })}
    </div>
  );
};

const fontSizeMap = {
  xs: 'text-xs',
  small: 'text-sm',
  default: '',
  medium: '',
  large: '',
  xxxl: 'text-lg',
};
const iconSizeMap = {
  xs: 'w-3 h-3',
  small: 'w-3.5 h-3.5',
  default: 'w-4 h-4',
  medium: 'w-4 h-4',
  large: 'w-4 h-4',
  xxxl: '',
};
const sizeMap = {
  xs: 'w-5 h-5',
  small: 'w-7 h-7',
  default: 'w-8 h-8',
  medium: 'w-10 h-10',
  large: 'w-12 h-12',
  xxxl: 'w-36 h-36',
};
export const WsAvatar = (props: {
  photoURL?: string;
  name?: string;
  size?: keyof typeof sizeMap;
  hasOutline?: boolean;
  isWorkspace?: boolean;
  isInline?: boolean;
}) => {
  const {
    photoURL,
    name,
    size = 'default',
    hasOutline = false,
    isWorkspace = false,
    isInline = false,
  } = props;
  return (
    <Avatar
      className={cn(
        sizeMap[size],
        hasOutline && 'outline-white',
        isInline && 'mx-auto'
      )}
    >
      <AvatarImage src={photoURL} alt={`@${name}`} className={'object-cover'} />
      <AvatarFallback className={cn(fontSizeMap[size])}>
        {name ? (
          <>
            {name
              .split(' ')
              .map(word => word[0])
              .filter(char => /^[A-Za-z]$/.test(char))
              .filter(
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                (char, index, arr) =>
                  !/^(V[0-9]|VO)$/i.test(name.split(' ')[index])
              )
              .join('')
              .toUpperCase()}
          </>
        ) : (
          <>
            {isWorkspace ? (
              <SwatchBook className={cn(iconSizeMap[size])} />
            ) : (
              <User className={cn(iconSizeMap[size])} />
            )}
          </>
        )}
      </AvatarFallback>
    </Avatar>
  );
};
